exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-browse-tsx": () => import("./../../../src/pages/browse.tsx" /* webpackChunkName: "component---src-pages-browse-tsx" */),
  "component---src-pages-changelog-tsx": () => import("./../../../src/pages/changelog.tsx" /* webpackChunkName: "component---src-pages-changelog-tsx" */),
  "component---src-pages-cms-tsx": () => import("./../../../src/pages/cms/[...].tsx" /* webpackChunkName: "component---src-pages-cms-tsx" */),
  "component---src-pages-country-explorer-[abbr]-tsx": () => import("./../../../src/pages/country-explorer/[abbr].tsx" /* webpackChunkName: "component---src-pages-country-explorer-[abbr]-tsx" */),
  "component---src-pages-country-explorer-index-tsx": () => import("./../../../src/pages/country-explorer/index.tsx" /* webpackChunkName: "component---src-pages-country-explorer-index-tsx" */),
  "component---src-pages-dau-mau-rankings-index-tsx": () => import("./../../../src/pages/dau-mau-rankings/index.tsx" /* webpackChunkName: "component---src-pages-dau-mau-rankings-index-tsx" */),
  "component---src-pages-dau-mau-rankings-ranking-type-tsx": () => import("./../../../src/pages/dau-mau-rankings/[rankingType].tsx" /* webpackChunkName: "component---src-pages-dau-mau-rankings-ranking-type-tsx" */),
  "component---src-pages-details-[masterid]-index-tsx": () => import("./../../../src/pages/details/[masterid]/index.tsx" /* webpackChunkName: "component---src-pages-details-[masterid]-index-tsx" */),
  "component---src-pages-details-[masterid]-pro-insight-tsx": () => import("./../../../src/pages/details/[masterid]/pro-insight.tsx" /* webpackChunkName: "component---src-pages-details-[masterid]-pro-insight-tsx" */),
  "component---src-pages-details-index-tsx": () => import("./../../../src/pages/details/index.tsx" /* webpackChunkName: "component---src-pages-details-index-tsx" */),
  "component---src-pages-game-affinity-explorer-tsx": () => import("./../../../src/pages/game-affinity-explorer.tsx" /* webpackChunkName: "component---src-pages-game-affinity-explorer-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-login-password-reset-tsx": () => import("./../../../src/pages/login/password-reset.tsx" /* webpackChunkName: "component---src-pages-login-password-reset-tsx" */),
  "component---src-pages-most-hyped-games-tsx": () => import("./../../../src/pages/most-hyped-games.tsx" /* webpackChunkName: "component---src-pages-most-hyped-games-tsx" */),
  "component---src-pages-p-[slug]-tsx": () => import("./../../../src/pages/p/[slug].tsx" /* webpackChunkName: "component---src-pages-p-[slug]-tsx" */),
  "component---src-pages-p-index-tsx": () => import("./../../../src/pages/p/index.tsx" /* webpackChunkName: "component---src-pages-p-index-tsx" */),
  "component---src-pages-platforms-[platform]-tsx": () => import("./../../../src/pages/platforms/[platform].tsx" /* webpackChunkName: "component---src-pages-platforms-[platform]-tsx" */),
  "component---src-pages-platforms-index-tsx": () => import("./../../../src/pages/platforms/index.tsx" /* webpackChunkName: "component---src-pages-platforms-index-tsx" */),
  "component---src-pages-pro-insight-tsx": () => import("./../../../src/pages/pro-insight.tsx" /* webpackChunkName: "component---src-pages-pro-insight-tsx" */),
  "component---src-pages-publisher-developer-[pubdevid]-tsx": () => import("./../../../src/pages/publisher-developer/[pubdevid].tsx" /* webpackChunkName: "component---src-pages-publisher-developer-[pubdevid]-tsx" */),
  "component---src-pages-publisher-developer-index-tsx": () => import("./../../../src/pages/publisher-developer/index.tsx" /* webpackChunkName: "component---src-pages-publisher-developer-index-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-settings-tsx": () => import("./../../../src/pages/settings.tsx" /* webpackChunkName: "component---src-pages-settings-tsx" */),
  "component---src-pages-tag-explorer-index-tsx": () => import("./../../../src/pages/tag-explorer/index.tsx" /* webpackChunkName: "component---src-pages-tag-explorer-index-tsx" */),
  "component---src-pages-tag-explorer-tsx": () => import("./../../../src/pages/tag-explorer/[...].tsx" /* webpackChunkName: "component---src-pages-tag-explorer-tsx" */),
  "component---src-pages-top-genres-by-hype-tsx": () => import("./../../../src/pages/top-genres-by-hype.tsx" /* webpackChunkName: "component---src-pages-top-genres-by-hype-tsx" */)
}

